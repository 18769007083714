/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import canUseDOM from 'exenv';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';

export default class {
  constructor(
    playerTitle,
    type,
    adslot,
    readTime,
    pos,
    firstimp,
    extracustomparams,
    shouldUseDisplayPath
  ) {
    this.title = this.sanitizeTitle(playerTitle, type);
    this.shouldUseDisplayPath = shouldUseDisplayPath || false;
    this.adZone = this.buildAdZone();
    this.adTag = this.buildAdURL(
      adslot,
      readTime,
      pos,
      firstimp,
      extracustomparams
    );
  }

  getParameterByName(name) {
    let paramName = name;
    const url = window.location.href;
    paramName = paramName.replace(/[[\]]/g, '\\$&');

    const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  buildAdURL(adslot, readTime, pos, firstimp, extracustomparams) {
    const adZoneParam = this.getParameterByName('adzone');
    const cmsValueParam = this.getParameterByName('cms');
    const CIDValueParam = this.getParameterByName('cid') || '0';
    let customParams = `video_tags%3D__item-tags__%26vport%3D${this.getViewport()}%26video_id%3D__item-mediaid__`;

    if (canUseDOM) {
      // adzone override, add ?adzone=test to url to activate
      if (adZoneParam) {
        this.adZone = `/4160/mv.fc/${adZoneParam}`;
      }

      // custom param override, add ?cms=test to url to activate
      if (cmsValueParam) {
        customParams = `${customParams}%26cms%3D${cmsValueParam}`;
      }

      if (CIDValueParam) {
        customParams = `${customParams}%26CID%3D${CIDValueParam}`;
      }
    }

    if (adslot) {
      customParams = `${customParams}%26adslot%3D${adslot}`;
    } else {
      customParams = `${customParams}%26adslot%3Dpreroll_${this.title}`;
    }

    if (readTime) {
      const readTimeSplit = readTime.split(' ')[0];
      const readTimeSanitized = isNumber(readTimeSplit)
        ? Number(readTimeSplit)
        : readTimeSplit;
      customParams = `${customParams}%26artLength%3D${readTimeSanitized}`;
    } else {
      customParams = `${customParams}%26artLength%3DNA`;
    }

    if (pos) {
      customParams = `${customParams}%26pos%3D${pos}`;
    }

    if (firstimp) {
      customParams = `${customParams}%26firstimp%3Dtrue`;
    }

    if (window.top.moatPrebidApi) {
      const obj = window.top.moatPrebidApi.getMoatTargetingForPage();

      let moatPageLevelTargeting = '';
      for (const prop in obj) {
        moatPageLevelTargeting += `${prop}=${obj[prop]}&`;
      }

      moatPageLevelTargeting = moatPageLevelTargeting.split('');
      moatPageLevelTargeting.pop();
      moatPageLevelTargeting = moatPageLevelTargeting.join('');

      moatPageLevelTargeting = encodeURIComponent(moatPageLevelTargeting);
      // console.log('moatPageLevelTargeting', moatPageLevelTargeting);
      customParams = `${customParams}%26${moatPageLevelTargeting}`;
    } else {
      // console.log('❌❌❌❌❌❌');
    }

    if (window.localStorage) {
      const bcDFPTargetingParams = window.localStorage.getItem(
        'bcDFPTargetingParams'
      );
      if (bcDFPTargetingParams) {
        const targetingParams = JSON.parse(bcDFPTargetingParams);
        const kvStrings = [];
        targetingParams.forEach(kv =>
          kvStrings.push(`${kv.key}%3D${kv.value}`)
        );

        if (kvStrings.length) {
          customParams = `${customParams}%26${kvStrings.join('%26')}`;
        }
      }
    }

    if (window.googletag) {
      const bmbValues = window.googletag.pubads().getTargeting('bmb');
      if (bmbValues.length) {
        customParams = `${customParams}%26bmb%3d${bmbValues.join(',')}`;
      }
    }

    // Add `chn`, `schn`, and `tags` to custom params for all requests
    customParams += `%26chn=${
      window?.digitalData?.ads?.targeting?.chn?.[0]
    }%26schn=${window?.digitalData?.ads?.targeting?.schn?.[0]}%26tags=${
      window?.digitalData?.ads?.targeting?.tags?.[0]
    }`;

    if (
      typeof extracustomparams === 'object' &&
      Object.keys(extracustomparams).length > 0 &&
      extracustomparams !== false
    ) {
      let extraCustomParamsString = '';
      for (const prop in extracustomparams) {
        extraCustomParamsString += `${prop}=${extracustomparams[prop]}&`;
      }

      extraCustomParamsString = extraCustomParamsString.split('');
      extraCustomParamsString.pop();
      extraCustomParamsString = extraCustomParamsString.join('');

      extraCustomParamsString = encodeURIComponent(extraCustomParamsString);
      customParams = `${customParams}%26${extraCustomParamsString}`;
    }

    // Append the user's AB group to the custom params
    // eslint-disable-next-line
    const foundUserAbGroup = window?._ash?.requestAbGroup;
    customParams = `${customParams}%26${encodeURIComponent(
      `abgroup=${foundUserAbGroup}`
    )}`;

    // prettier-ignore
    const tag =
      `${'//pubads.g.doubleclick.net/gampad/ads?' +
        'sz=640x360' +
        '&impl=s' +
        '&gdfp_req=1' +
        '&env=vp' +
        '&output=vast' +
        '&unviewed_position_start=1' +
        '&vad_type=linear' +
        '&vpos=preroll' +
        '&cms='}${this.constructCMSHash()}&iu=${this
        .adZone
      // Custom Params:
      // TESTING:
      // }&cust_params=cms%3Ddfp_video_test` +
      // PRODUCTION:
      }&cust_params=${customParams}`;

    return tag;
  }

  constructCMSHash() {
    const cmsQueryValue = this.getParameterByName('cms');
    if (cmsQueryValue) {
      return cmsQueryValue;
    }

    const key = document.location.href;
    let hash = 0;
    let i;
    let chr;

    if (!key || key.length === 0) {
      return hash;
    }

    // eslint-disable-next-line no-plusplus
    for (i = 0; i < key.length; i++) {
      chr = key.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + chr;
      // eslint-disable-next-line
      hash &= hash;
    }
    hash = Math.abs(hash);
    hash = hash.toString(36);
    return hash;
  }

  sanitizeTitle(title, type) {
    if (type) {
      return type;
    }
    switch (true) {
      case title.match(/Homepage/) !== null:
        return 'homepage';
      case title.match(/Infinite/) !== null:
        return 'infinitescroll';
      default:
        return 'homepage';
    }
  }

  buildAdZone() {
    let adZone = '/4160/mv.fc';
    if (this.shouldUseDisplayPath) {
      const adPath = get(window.digitalData, 'ads.adPath') || adZone;
      adZone = `${adPath}/preroll_${this.title}`;
    } else {
      adZone = `${adZone}/__item-category__/__item-series__/preroll_${
        this.title
      }`;
    }
    return adZone;
  }

  getViewport() {
    if (canUseDOM) {
      const w = window.innerWidth;

      if (w > 1023) {
        return 'l';
      }

      if (w < 640) {
        return 's';
      }

      return 'm';
    }
    return 'undefined';
  }
}
