import { canUseDOM } from 'exenv';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Slider from 'react-slick';
import Cookies from 'js-cookie';
import ReactJWPlayer from 'react-jw-player';
import GoogleIMAGenerator from './utils/video_module_ads';
import Slug from '../slug/slug';
import Card from '../card/card';
import { slugify } from '../../../utils/string_util';
import log from '../../../services/logger_service';

let scriptjs = false;
let ifvisible = false;

if (canUseDOM) {
  // eslint-disable-next-line global-require
  scriptjs = require('scriptjs');
  // eslint-disable-next-line global-require
  ifvisible = require('ifvisible.js');
}

export default class Video extends Component {
  constructor() {
    super();

    this.state = {
      playingIndex: 0,
      activeTitle: '',
      userIsActive: true,
      firstimp: false,
      adDataForMoat: null,
      calledMoatEventTrackerMarker: {}
    };
    this.videoContainerRef = React.createRef();
    this.MoatApiReference = null;

    // variable init
    this.adPosition = 0;
    this.lastKnownPlaybackPosition = 0;
    this.lastSystemClock = 0;
    this.mediaInfo = {};
    this.customVideoMetadata = {};

    // flags
    this.sessionStarted = false;
    this.playSent = false;
    this.adsPlaying = false;

    this.videoCardClick = this.videoCardClick.bind(this);
    this.generatePreRoll = this.generatePreRoll.bind(this);
    this.setMoatTracking = this.setMoatTracking.bind(this);
    this.getVideoImage = this.getVideoImage.bind(this);
    this.getVideoList = this.getVideoList.bind(this);
    this.setNextVideo = this.setNextVideo.bind(this);
    this.onVideoLoad = this.onVideoLoad.bind(this);
    this.generateShareLink = this.generateShareLink.bind(this);
    this.onReady = this.onReady.bind(this);
    this.trackMoatVideoProgress = this.trackMoatVideoProgress.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      settings: {
        arrows: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.3,
              slidesToScroll: 2,
              slide: '.video-slide'
            }
          },
          {
            breakpoint: 100000,
            settings: 'unslick'
          }
        ]
      }
    });

    if (scriptjs) {
      scriptjs(['https://z.moatads.com/jwplayerplugin0938452/moatplugin.js']);
    }

    // Set up activity tracker, 5s = idle
    if (ifvisible) {
      ifvisible.setIdleDuration(5);
      ifvisible.on('idle', () => {
        this.setState({
          userIsActive: false
        });
      });

      ifvisible.on('wakeup', () => {
        this.setState({
          userIsActive: true
        });
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const updateSettings = !this.state.settings;
    const updatePlayingIndex =
      this.state.playingIndex !== nextState.playingIndex;
    const updateActiveTitle = this.state.activeTitle !== nextState.activeTitle;

    return updateSettings || updatePlayingIndex || updateActiveTitle;
  }

  componentWillUnmount() {
    if (canUseDOM) {
      if (window.moatjw) {
        window.moatjw.removeAll();
      }

      if (ifvisible) {
        ifvisible.off('idle');
        ifvisible.off('wakeup');
      }
    }
  }

  onVideoLoad(event) {
    this.setState({
      activeTitle: get(event, 'item.title')
    });
  }

  onReady() {
    // const { post } = this.props;
    // const playerid = get(post, 'playerScriptId');
    // const player = window.jwplayer(playerid);
    // const caption = player.getMute() ? 1 : 0;

    const { playerid } = this.props;
    const player = window.jwplayer(playerid);
    const caption = player.getMute() ? 1 : 0;

    this.player = player;

    player.setCurrentCaptions(caption);

    player.on('playlistItem', () => {
      this.customVideoMetadata = {
        playerDimensions: `${player.getContainer().offsetWidth}x${
          player.getContainer().offsetHeight
        }`,
        abgroup: window?._ash?.requestAbGroup // eslint-disable-line no-underscore-dangle
      };

      // Initialize play positions
      //
      this.lastKnownPlaybackPosition = 0;
      this.lastSystemClock = 0;
      this.playSent = false;
    });

    player.on('beforePlay', () => {
      if (player.getPosition() === 0) {
        if (!this.sessionStarted) {
          this.sessionStarted = true;
        }
      }
    });

    player.on('firstFrame', () => {
      // If haven't sent the play event then send it.  Note that it's possible the
      // play vent was sent when the AdStart event was signaled
      if (!this.playSent) {
        this.PlaySent = true;
      }
    });

    player.on('time', data => {
      const d = new Date();
      this.lastSystemClock = d.getTime();
      this.lastKnownPlaybackPosition = data.position;

      // custom code specific for this component
      const percentPlayed = Math.floor(data.position * 100 / data.duration);
      const marker = {};

      switch (percentPlayed) {
        case 1:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoStart) {
            this.trackMoatVideoProgress('AdVideoStart', player, data);
            marker.AdVideoStart = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 25:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoFirstQuartile) {
            this.trackMoatVideoProgress('AdVideoFirstQuartile', player, data);
            marker.AdVideoFirstQuartile = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 50:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoMidpoint) {
            this.trackMoatVideoProgress('AdVideoMidpoint', player, data);
            marker.AdVideoMidpoint = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 75:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoThirdQuartile) {
            this.trackMoatVideoProgress('AdVideoThirdQuartile', player, data);
            marker.AdVideoThirdQuartile = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 99:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoComplete) {
            this.trackMoatVideoProgress('AdVideoComplete', player, data);
            marker.AdVideoComplete = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        default:
          break;
      }
    });

    player.on('adTime', data => {
      const d = new Date();
      this.lastSystemClock = d.getTime();
      this.lastKnownPlaybackPosition = data.position;
    });

    player.on('seek', () => {});

    player.on('seeked', () => {
      this.lastKnownPlaybackPosition = player.getPosition(); // Save last known position
    });

    // User paused the video
    player.on('pause', e => {
      // custom code specific for this component
      this.trackMoatVideoProgress('AdPaused', player, e);
    });

    // If the play event is fired and ads were playing,
    // then send the AdBreakComplete event.  There is
    // no ad playback end event
    //
    player.on('play', e => {
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }

      // custom code specific for this component
      this.trackMoatVideoProgress('AdPlaying', player, e);
    });

    player.on('complete', () => {
      // If an ad was playing (POST-ROLL), send the AdBreakComplete
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }

      this.resetSession();

      // Force player stop if user determined to be inactive
      if (!this.state.userIsActive) {
        player.stop();
      }

      // custom code specific for this component
      this.setState({
        firstimp: false
      });

      if (!this.state.userIsActive) {
        player.stop();
      }
    });

    // Only send the buffer event if the session has been started
    // We don't send one just because the player loaded and pre-buffered
    player.on('buffer', () => {});
    player.on('bufferFull', () => {});

    player.on('error', () => {
      // error means the player stopped so reset the session
      this.resetSession();
    });

    player.on('setupError', () => {
      this.resetSession();
    });

    player.on('adImpression', data => {
      const adTitle = get(data, 'adtitle', 'unknown-title');
      const adWrapperCreativeId = get(
        data,
        'ima.ad.g.adWrapperCreativeIds[0]',
        'unknown-wrapper-creative-id'
      );
      const adWrapperId = get(
        data,
        'ima.ad.g.adWrapperIds[0]',
        'unknown-wrapper-id'
      );
      const adCreativeId = get(
        data,
        'ima.ad.g.creativeId',
        'unknown-creative-id'
      );
      const adName = `${adTitle} | ${adWrapperCreativeId} | ${adWrapperId} | ${adCreativeId}`;

      // Start the adBreak if needed
      this.adStartBreak(
        adName,
        this.adPosition,
        this.lastKnownPlaybackPosition
      );

      // Note: The documentation says that after the first ad in a Pre-roll
      // starts to play, the 'trackPlay' event should be sent.  But, it seems,
      // in practice, this does not work correctly.  The adStart event that is sent
      // above in the adImpression handler, automatically kicks off the trackPlay
      // event.  Thus, just set the gPlaySent flag to true, so it is not sent again
      //
      if (!this.playSent) {
        this.playSent = true;
      }
      // Set the flag that an Ad is playing
      //
      this.adsPlaying = true;

      // Track with Moat
      // this.trackMoat(player, data);
    });

    player.on('adPlay', () => {
      if (!this.playSent) {
        this.playSent = true;
      }
    });

    player.on('adStarted', () => {});

    player.on('adSkipped', () => {
      // Set the flag to false so the AdBreakComplete is not sent
      // unless a new ad starts playing
      this.adsPlaying = false;
    });

    player.on('adComplete', () => {});

    player.on('adBreakEnd', () => {
      // If an ad was playing send the AdBreakComplete
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }
    });

    player.on('remove', () => {
      // Close the session if it's still in progress
      if (this.sessionStarted) {
        this.resetSession();
      }
    });

    // player.on('adRequest', ad => {
    //   // console.log('[ads] === video_module player.on adRequest', ad);
    //   const adDataDFP = get(ad, 'ima.ad.g');
    //   const { adId, creativeId, title } = adDataDFP;

    //   const adDataForMoat = {
    //     level1:
    //       get(adDataDFP, 'advertiserName', `advertiser for unit: ${title}`) ||
    //       `advertiser for unit: ${title}`,
    //     level2: title,
    //     level3: adId,
    //     level4: creativeId,
    //     slicer1: 'fastcompany',
    //     slicer2: creativeId
    //   };

    //   this.setState({
    //     adDataForMoat,
    //     calledMoatEventTrackerMarker: {}
    //   });
    //   this.MoatApiReference = null;
    // });

    this.setMoatTracking(player);
  }

  // onZeady() {
  //   const { playerid } = this.props;
  //   const player = window.jwplayer(playerid);

  //   const caption = player.getMute() ? 1 : 0;
  //   player.setCurrentCaptions(caption);

  //   player.on('complete', () => {
  //     this.setState({
  //       firstimp: false
  //     });

  //     if (!this.state.userIsActive) {
  //       player.stop();
  //     }
  //   });

  //   player.onTime(e => {
  //     // over 10 sec, send tracking to JW
  //     const percentPlayed = Math.floor(e.position * 100 / e.duration);
  //     const marker = {};

  //     switch (percentPlayed) {
  //       case 1:
  //         if (!this.state.calledMoatEventTrackerMarker.AdVideoStart) {
  //           this.trackMoatVideoProgress('AdVideoStart', player, e);
  //           marker.AdVideoStart = true;
  //           this.setState({
  //             calledMoatEventTrackerMarker: marker
  //           });
  //         }
  //         break;
  //       case 25:
  //         if (!this.state.calledMoatEventTrackerMarker.AdVideoFirstQuartile) {
  //           this.trackMoatVideoProgress('AdVideoFirstQuartile', player, e);
  //           marker.AdVideoFirstQuartile = true;
  //           this.setState({
  //             calledMoatEventTrackerMarker: marker
  //           });
  //         }
  //         break;
  //       case 50:
  //         if (!this.state.calledMoatEventTrackerMarker.AdVideoMidpoint) {
  //           this.trackMoatVideoProgress('AdVideoMidpoint', player, e);
  //           marker.AdVideoMidpoint = true;
  //           this.setState({
  //             calledMoatEventTrackerMarker: marker
  //           });
  //         }
  //         break;
  //       case 75:
  //         if (!this.state.calledMoatEventTrackerMarker.AdVideoThirdQuartile) {
  //           this.trackMoatVideoProgress('AdVideoThirdQuartile', player, e);
  //           marker.AdVideoThirdQuartile = true;
  //           this.setState({
  //             calledMoatEventTrackerMarker: marker
  //           });
  //         }
  //         break;
  //       case 99:
  //         if (!this.state.calledMoatEventTrackerMarker.AdVideoComplete) {
  //           this.trackMoatVideoProgress('AdVideoComplete', player, e);
  //           marker.AdVideoComplete = true;
  //           this.setState({
  //             calledMoatEventTrackerMarker: marker
  //           });
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   player.on('play', e => {
  //     this.trackMoatVideoProgress('AdPlaying', player, e);
  //   });

  //   player.on('pause', e => {
  //     this.trackMoatVideoProgress('AdPaused', player, e);
  //   });

  //   player.on('adRequest', ad => {
  //     const adDataDFP = get(ad, 'ima.ad.g');
  //     const { adId, creativeId, title } = adDataDFP;

  //     const adDataForMoat = {
  //       level1:
  //         get(adDataDFP, 'advertiserName', `advertiser for unit: ${title}`) ||
  //         `advertiser for unit: ${title}`,
  //       level2: title,
  //       level3: adId,
  //       level4: creativeId,
  //       slicer1: 'fastcompany',
  //       slicer2: creativeId
  //     };

  //     this.setState({
  //       adDataForMoat,
  //       calledMoatEventTrackerMarker: {}
  //     });
  //     this.MoatApiReference = null;
  //   });
  //   this.setMoatTracking(player);
  // }

  getVideoImage(image) {
    if (!image) return '';

    return `https://images.fastcompany.net/image/fetch/w_280,ar_16:9,c_fill,g_auto,q_auto,f_auto,fl_lossy/${image}`;
  }

  getVideoList() {
    const { stories } = this.props;

    return stories.map((video, index) => ({
      id: video.mediaid,
      title: video.title,
      link: video.link,
      featured_image: {
        source: this.getVideoImage(get(video, 'image'))
      },
      isPlayerVideo: true,
      isVideoActive: this.state.playingIndex === index,
      index
    }));
  }

  setMoatTracking(player) {
    if (canUseDOM) {
      if (window.moatjw) {
        player.on('adImpression', event => {
          window.moatjw.add({
            partnerCode: 'mansuetojwint127635890115',
            player,
            adImpressionEvent: event
          });
        });
      }
    }
  }

  setNextVideo() {
    const playingIndex = this.state.playingIndex + 1;
    this.setState({ playingIndex });
  }

  resetSession() {
    this.sessionStarted = false; // Will need to reinitialize for next video
    this.playSent = false;
  }

  adStartBreak() {
    if (!this.adsPlaying) {
      this.adsPlaying = true;
      this.adPosition = 0;
    } else {
      this.adPosition = this.adPosition + 1; // Just increase the adPosition value
    }
  }

  trackMoatVideoProgress(eventType, player, e) {
    const MoatTrackingPartnerCode = 'mansuetojwcontent613342534978';
    if (!this.MoatApiReference) {
      this.MoatApiReference = window.initMoatTracking(
        this.videoContainerRef.current,
        this.state.adDataForMoat,
        e.duration,
        MoatTrackingPartnerCode,
        null
      );
    }

    this.MoatApiReference.dispatchEvent({
      type: eventType,
      adVolume: player.getMute() ? 0 : (player.getVolume() / 100).toFixed(1)
    });
  }

  generatePreRoll() {
    const { data, type, pos, readTime } = this.props;
    const customparams = get(this.props, 'customparams', false);

    if (!Cookies.get('dailycookie') && !this.state.alreadySawFirstImpression) {
      this.setState({
        firstimp: true,
        alreadySawFirstImpression: true
      });
    }

    return new GoogleIMAGenerator(
      get(data, 'title'),
      type,
      'preroll_rightrail',
      readTime,
      pos,
      this.state.firstimp,
      customparams
    ).adTag;
  }

  videoCardClick(event, index) {
    const { playerid } = this.props;
    const player = window.jwplayer(playerid);
    const caption = player.getMute() ? 1 : 0;
    this.setState({ playingIndex: index });
    player.playlistItem(index);
    player.setCurrentCaptions(caption);
  }

  generateShareLink(data) {
    return `https://www.fastcompany.com/video/${slugify(
      get(data.playlist[this.state.playingIndex], 'title')
    )}/${get(data.playlist[this.state.playingIndex], 'mediaid')}`;
  }

  renderSlides() {
    const { settings } = this.state;
    const { config } = this.props;
    if (settings) {
      const stories = this.getVideoList();
      return (
        <div className="video-module-playlist">
          <Slider {...settings}>
            {stories.map((item, index) => (
              <div
                className="video-slide video-slide--vertical"
                key={`${item.id}${index}`}
              >
                <Card
                  type="video"
                  config={config}
                  metadata={item}
                  cardEvent={this.videoCardClick}
                  hasLink={false}
                  defaultHeight={158}
                  defaultWidth={280}
                />
              </div>
            ))}
          </Slider>
        </div>
      );
    }

    return null;
  }

  render() {
    const { slugName, isPost, data, playerid, hideHeader } = this.props;

    return (
      <section className={`video-row ${isPost ? 'video-row--post' : ''}`}>
        {!hideHeader && (
          <Slug linkTo="//fastcompany.com/videos" slugName={slugName} />
        )}
        <div className="video-module">
          <div
            className="video-module__player-container"
            ref={this.videoContainerRef}
          >
            <ReactJWPlayer
              playerId={playerid}
              customProps={{
                sharing: {
                  link: this.generateShareLink(data)
                }
              }}
              playerScript={`https://content.jwplatform.com/libraries/${playerid}.js`}
              playlist={`//content.jwplatform.com/feeds/${data.feedid}.json`}
              generatePrerollUrl={this.generatePreRoll}
              onReady={this.onReady}
              playInView={true}
              onVideoLoad={this.onVideoLoad}
              onOneHundredPercent={this.setNextVideo}
              onError={event => {
                log.error(
                  'Siderail ReactJW Video Player Error',
                  get(event, 'message')
                );
              }}
              onSetupError={event => {
                log.error(
                  'Siderail ReactJW Video Player Error',
                  get(event, 'message')
                );
              }}
              isMuted={true}
              isAutoPlay={false}
              playInViewPercentage={100}
              aspectRatio="16:9"
              // useMultiplePlayerScripts={true}
            />
          </div>
          <div className="video-module__title">{this.state.activeTitle}</div>
        </div>
        {this.renderSlides()}
      </section>
    );
  }
}

Video.defaultProps = {
  stories: [],
  data: {},
  slugName: 'Video',
  isPost: false,
  readTime: null,
  type: '',
  pos: '',
  customparams: {},
  hideHeader: false
};

Video.propTypes = {
  config: PropTypes.shape({}).isRequired,
  stories: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({}),
  playerid: PropTypes.string.isRequired,
  slugName: PropTypes.string,
  isPost: PropTypes.bool,
  type: PropTypes.string,
  pos: PropTypes.string,
  readTime: PropTypes.string,
  customparams: PropTypes.shape({}),
  hideHeader: PropTypes.bool
};
